import Service from './Service';

const resource = '';

export default {
    authenticate(credentials) {
        return Service.post(`${resource}/login`, credentials);
    },
    logout(){
        return Service.post(`${resource}/logout`);
    },
    getAuthenticatedUser(){
        return Service.get(`${resource}/api/user/current`);
    }
}

