import Service from './Service';

const resource = '/api/menu';

export default {
    getAuthenticatedUserMenu(){
        let url = `${resource}`;
        return Service.get(url);
    },
    getMenu(){
        return  Service.get(`${resource}/options`);
    }
}
