<template>
  <div class="sidenav-menu">
    <div class="nav accordion" id="accordionSidenav">
      <template v-for="option in menu_options">
        <a
          class="nav-link collapsed"
          href="javascript:void(0);"
          data-toggle="collapse"
          :data-target="'#menu_collapse' + option.id"
          aria-expanded="false"
          aria-controls="collapseDashboards"
          :key="option.id"
        >
          <div class="nav-link-icon" v-html="option.icon"></div>
          {{ option.name }}
          <div class="sidenav-collapse-arrow">
            <i class="fas fa-angle-down"></i>
          </div>
        </a>
        <div
          class="collapse"
          :id="'menu_collapse' + option.id"
          :key="'menu_collapse' + option.id"
          data-parent="#accordionSidenav"
        >
          <nav
            class="sidenav-menu-nested nav accordion"
            id="accordionSidenavPages"
          >
            <div v-for="(sub, i) in option.options" :key="`menu_${i}`">
              <div v-if="sub.options.length">
                <a
                  class="nav-link collapsed"
                  href="javascript:void(0);"
                  data-toggle="collapse"
                  :id="'menu_option_' + sub.id"
                  :data-target="'#subItemsCollapse_' + sub.id"
                  aria-expanded="false"
                  :aria-controls="'subItemsCollapse_' + sub.id"
                >
                  <div class="nav-link-icon" v-html="sub.icon"></div>
                  {{ sub.name }}
                  <div class="sidenav-collapse-arrow">
                    <i class="fas fa-angle-down"></i>
                  </div>
                </a>
                <div
                  class="collapse"
                  :id="'subItemsCollapse_' + sub.id"
                  :data-parent="'#menu_option_' + sub.id"
                >
                  <nav class="sidenav-menu-nested nav">
                    <div
                      v-for="subitem in sub.options"
                      :key="'link_' + subitem.id"
                    >
                      <router-link
                        v-if="app_name === sub.app"
                        :to="subitem.route"
                        class="nav-link"
                        v-html="subitem.icon + ' ' + subitem.name"
                      ></router-link>
                      <a
                        v-else
                        :href="`${domain_app_admin}${subitem.route}`"
                        class="nav-link"
                        v-html="subitem.icon + ' ' + subitem.name"
                      ></a>
                    </div>
                  </nav>
                </div>
              </div>
              <div v-else>
                <router-link v-if="app_name === sub.app"
                  :key="'link_' + sub.id"
                  :to="sub.route"
                  class="nav-link"
                  v-html="sub.icon + ' ' + sub.name"
                ></router-link>
                <a
                    v-else
                    :href="`${domain_app_admin}${sub.route}`"
                    class="nav-link"
                    v-html="sub.icon + ' ' + sub.name"
                ></a>
              </div>
            </div>
          </nav>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import typeAppService from "../../../services/typeAppService";

export default {
  props: ["options"],
  data() {
    return {
      type_app: {},
      domain_app_admin: "",
      domain_app_history:"",
      menu_options : [],
    };
  },
  async created() {
    const response = await typeAppService.index();
    this.type_app = response.data;
    this.domain_app_admin =response.data.find((x) => x.name === "ADMIN")?.domain || "";
    this.domain_app_history =response.data.find((x) => x.name === "HISTORIA")?.domain || "";
    this.menu_options = this.options;

  },
  computed:{
    app_name: function (){
        return process.env.VUE_APP_NAME;
    }
  },
  
};
</script>
